import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PageVerify from './pages/PageVerify';
import Page404 from './pages/Page404';

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/:token" element={<PageVerify />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}