import APIServiceLookUp from './serviceAPI';
import { API_URL, IDP_API_URL, CMS_API_URL } from './models/AppConstent';

export default class ServiceVerify {
    lookupService = new APIServiceLookUp();

    async initProject(body, token) {
        var serviceType = "POST";
        var url = `${IDP_API_URL}project/initProject`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async verifyToken(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}verifyToken`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async profileVerify(body, token) {
        var serviceType = "POST";
        var url = `${IDP_API_URL}auth/userUpdate`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async PasswordUpdate(body, token) {
        var serviceType = "POST";
        var url = `${IDP_API_URL}auth/userPasswordUpdate`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async StaffPasswordUpdate(body, token) {
        var serviceType = "POST";
        var url = `${CMS_API_URL}staff/resetPassword`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}